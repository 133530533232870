import React from "react";
import { Helmet } from "react-helmet";

export default function Head() {
  const title = "vstpv";

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta
        name="description"
        content="Vladyslav Stupaiev personal site. In other words - vstpv space"
      />
      <meta name="keywords" content="vstpv" />

      <meta property="og:type" content="vladyslav.space" />
      {/* <meta property="og:title" name="og:title" content={title} /> */}
      <meta name="twitter:card" content={title} />
      <meta property="og:url" content="/" />
      <meta name="theme-color" content="#000" />
      {/*  <meta
        property="og:image"
        content={`${withPrefix("/")}img/og-theme-v.jpeg`}
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${withPrefix("/")}img/apple-icon.svg`}
      />

      <link
        rel="icon"
        type="image/svg"
        href={`${withPrefix("/")}img/apple-icon.svg`}
        sizes="128x128"
      />
      <link
        rel="icon"
        type="image/svg"
        href={`${withPrefix("/")}img/apple-icon.svg`}
        sizes="64x64"
      />
      <link
        rel="icon"
        type="image/svg"
        href={`${withPrefix("/")}img/apple-icon.svg`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/svg"
        href={`${withPrefix("/")}img/apple-icon.svg`}
        sizes="16x16"
      />

      <link
        rel="mask-icon"
        href={`${withPrefix("/")}img/og-theme.svg`}
        color="#000000"
      /> */}

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}
