import React from "react";
import Head from "./Head";

import "../../pages/global.scss";

export default function Layout({ children }) {
/*   // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  // Then we set the value in the --vh custom property to the root of the document
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }); */

  return (
    <>
      <Head />
      <SEO />
      <div className="base-container flex xy-center">{children}</div>
    </>
  );
}

function SEO() {
  const styles = {
    position: "fixed",
    opacity: 0,
  };

  return (
    <>
      <h1 style={styles}>vstpv</h1>
      <h2 style={styles}>Vladyslav Stupaiev personal sites</h2>
    </>
  );
}
